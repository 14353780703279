<template>
  <div class="container">
    <img :src="logo" alt="Cameroon Tribune" />
    <LoaderBookComponent />
    <p style="color: black;">Ouverture de votre magazine</p>
    <p></p>
    <div>
      <p class="text-center">
        <a href="https://nexah.net" class="text-black  underline" style="font-size: 11px; color: black;">Fourni par
          NEXAH</a>
      </p>
    </div>
  </div>
</template>

<script>
import LoaderBookComponent from "./LoaderBookComponent";
import logo from "../assets/logo.png";
export default {
  name: "PreviewLoaderComponent",
  data: () => {
    return {
      logo,
    };
  },
  components: {
    LoaderBookComponent,
  },
};
</script>

<style scoped>
.container {
  inset: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.98);
}

img {
  width: 13em;
  margin-bottom: 20px;
}
</style>
